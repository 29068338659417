import styled, { css } from 'styled-components'
import { device } from '../../utils/device'
// import { motion } from 'framer-motion'
// import { device, desktopDemoDevice } from '../../../utils/device'

export const Container = styled.div`
	display: none;
	@media ${device.isTabletOrLarger} { 
		/* border: 5px solid red; */
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100px;
		padding-top:20px;
		display: block;
  }
`

export const HeaderContainer = styled.div`
	position: relative;
	box-sizing: border-box; 
	width: 100%;
	height: 80px;
	/* border: 1px solid blue; */
`

export const HeaderImageContainer = styled.div`
	position: relative;
	top: 10px;
	left: 30px;
	height: 56px;
	width: 200px;
	background-color: transparent;
	/* border: 1px solid red; */
`

export const HeaderTitle = styled.div`
	position: absolute;
	top: 20px;
	left: 100px;
	height: 30px;
	/* border: 1px solid green; */
	font-family: var(--slideshowFontFamily);
	font-weight: var(--h1FontWeight);
	color: var(--gray1);
	font-size: 18px;
`

export const HeaderTagline = styled.div`
	position: absolute;
	top: 45px;
	left: 100px;
	height: 30px;
	font-family: 'DM Sans';
	font-weight: 400;
	color: #3F444B;
	font-size: 16px;
`

export const HeaderImage = styled.img`
	background-color: transparent;
  width: 100%;
	height: 100%;
	object-fit: contain;
`

export const Table = styled.div`
	position: relative;
	top: 10px;
	left: 0px;
	display : flex;
	flex-direction : column;
	justify-content : space-between;
`

export const TableRow = styled.div`
	font-family: 'DM Sans';
	font-weight: 400;
	color: #6C7586;
	font-size: 16px;
	/* border:1px solid green; */
	display: flex;
	justify-content: center;
	flex-flow: row nowrap;
	background-color: #ffffff;
	/* :nth-of-type(even) {
		background-color: #ffffff;
	}
	:nth-of-type(odd) {
		background-color: #f2f2f2;
	} */
`

export const TableHeaderRow = styled.div`
	font-family: 'DM Sans';
	font-weight: 500;
	color: #121A1C;
	font-size: '16px';
	background-color: #ffffff;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	height: 30px;
	padding-bottom: 4px;
  border-bottom-style: solid;
	 border-image: linear-gradient(to bottom, rgba(214, 219, 225, 0.5) 1%, rgba(196, 196, 196, 0) 100%) 
                0 0 100% 0/5px 0 5px 0 stretch;
`

export const TableRowItem = styled.div`
	width: 100px;
	padding: 0.5em;
	text-align: center;
	height: 15px;
	line-height: 15px;
`
export const TableRowItemMe = styled(TableRowItem)`
	color: var(--brandColor);
	height: 18px;
	line-height: 21px;
`

export const Textbox = styled.input`
	font-family:inherit;
	font-size: inherit;
	font-weight: inherit;
	color: inherit;
	height: 21px;
	line-height: 21px;
	width: 100px;
	/* max-height: 40px; */
	/* height: 40px; */
	padding: 0px;
	margin: 0px;
	margin-top:-5px;
	text-align: center;
	/* box-sizing: border-box; */
	border: none;
	&:focus {
			outline: none;
			box-shadow: none;
			border: none;
	}
	::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color:inherit;
		opacity: 1; /* Firefox */
	}
	${(props) => props.disabled && css`
     opacity 0.0;
  `}
	/* background: #EBEDEF; */
	/* border-radius: 20px; */
`
