import React from 'react'
import * as Styles from './styles'
import MyIcon from '../../images/svg/stickies_with_icon.svg'

const TopHeaderNavigation = ():JSX.Element => (
	<Styles.Container>
		<Styles.HeaderImageContainer>
			<MyIcon width={200} height={50} />
		</Styles.HeaderImageContainer>
		{/* <Styles.HeaderContainer>
        <Styles.HeaderImageContainer>
          <Styles.HeaderImage src={slideshow?.sticker?.url} alt="" />
        </Styles.HeaderImageContainer>
        <Styles.HeaderTitle>{slideshow?.name || 'Stickie'}</Styles.HeaderTitle>
        <Styles.HeaderTagline>{slideshow?.scoreboard?.tagline}</Styles.HeaderTagline>
      </Styles.HeaderContainer> */}
	</Styles.Container>
)

export default TopHeaderNavigation
