export const siteData = {
	twitterTitle: 'Stickies',
	twitterDescription: 'Virtual Items with Value!',
	siteTitle: 'Stickies',
	siteDescription: 'Virtual Items with Value!',
	// individualStickyDescription: 'Virtual Items with Value! By stickies.fun',
	individualStickyDescription: 'Virtual Items with Value!',
	// Stickies are a great way to express yourself.
	ogImageUrl: 'https://stickiesfundev.imgix.net/static/ogImage.png?fm=jpg&q=75',
	twitterImageUrl: 'https://stickiesfundev.imgix.net/static/twitterImage.png?fm=jpg&q=75',
	rootUrl: 'https://stickes.fun',
	iOSAppUrl: 'https://apps.apple.com/us/app/stickies-interactive-fun/id1569620768',
	iOSTestFlightUrl: 'https://testflight.apple.com/join/1TMygXYy',
	linkSentSignUpMessage: 'We sent a one-click sign-up link to',
	linkSentSignInMessage: 'We sent a one-click sign-in link to',
	bidLinkSentMessage: 'Check your email to secure your place in line.',
	finishPath: '/account/finish',
	finishBidPath: '/account/finishbid',
	finishSubscriptionPath: '/account/finishsubscription',
	shareButtonText: 'Share',
	bidButtonText: 'Make Bid',
}
