import React, { useEffect } from 'react'
import { useWindowHeight } from '@react-hook/window-size'
import styled from 'styled-components'
import { device, desktopDemoDevice } from '../utils/device'
import TopNavigation from '../components/TopNavigationHeader'
import '../styles/styles.css'

interface ComponentProps {
	scale?: number
	children: any
	scrollLock?: string
}

interface PhoneComponentProps {
	scale: number
	scrollLock: string
}

// const scaleInjection = css<ComponentProps>`transform: scale(${(props) => props.scale});transform-origin: top center;`
/* ${(scaleWindow) => (scaleWindow ? scaleInjection : '')} */

const Outside = styled.div<{scaleWindow: number|undefined}>`
	position: relative;
	transform: ${({ scaleWindow }) => (scaleWindow ? `scale(${scaleWindow});transform-origin: top center` : '')};
	background: var(--background);
	@media ${device.isTabletOrLarger} { 
		width: 100vw;
		height: 100vh;
		overflow-y: hidden;
		background-color: var(--gray4);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
  }
`

/* const StyledIPhoneX = styled.div` */
const StyledIPhoneX = styled.div.attrs((props:PhoneComponentProps) => ({
	scale: props.scale || 1,
	scrollLock: props.scrollLock || 'scroll',
}))`

	position: relative;
	width: 100vw;
	height: 100vh;
	overflow-y: ${(props) => props.scrollLock};
	::-webkit-scrollbar {
		display: none;
	}

	background-color: var(--background);
	
	@media ${device.isTabletOrLarger} {
		transform: ${(props) => `scale(${props.scale})`};
		transform-origin: 'center center';
		width: ${desktopDemoDevice.width}px;
		min-height: ${desktopDemoDevice.height}px;
		height: ${desktopDemoDevice.height}px;
		/* background-color: var(--gray1); */
		
		overflow-y: scroll;
		border-width: ${desktopDemoDevice.borderWidth}px;
		border-color: rgb(4, 4, 4);
		border-style: solid;
		border-radius: ${desktopDemoDevice.borderRadius}px;
		/* ::-webkit-scrollbar {
			display: none;
		} */
	}
`
/*
const PhoneComponent:React.FC<PhoneComponentProps> = (props) => {
	const { children, scale, scrollLock } = props

	return (
		<StyledIPhoneX scale={scale} scrollLock={scrollLock}>
			{children}
		</StyledIPhoneX>
	)
} */

const calcDesktopScale = (innerHeight:number):number => {
	let val = 1
	if (innerHeight <= 812 && innerHeight > 780) val = 0.9
	if (innerHeight <= 780 && innerHeight > 700) val = 0.8
	return val
}

const SmartLayout:React.FC<ComponentProps> = ({ scale, children, scrollLock = 'scroll' }) => {
	const onlyHeight = useWindowHeight({ initialHeight: 856 })
	const [desktopScale, setDesktopScale] = React.useState<number>(():number => calcDesktopScale(onlyHeight))

	useEffect(() => {
		setDesktopScale(calcDesktopScale(onlyHeight))
	}, [onlyHeight])

	return (
		<Outside scaleWindow={scale}>
			<TopNavigation />
			<StyledIPhoneX scale={desktopScale} scrollLock={scrollLock}>
				{children}
			</StyledIPhoneX>

			{/* <PhoneComponent
				scrollLock={scrollLock}
				scale={desktopScale}
			>
				{children}
			</PhoneComponent> */}
		</Outside>
	)
}

SmartLayout.defaultProps = {
	scale: undefined,
	scrollLock: 'scroll',
}

export default SmartLayout
